import React from "react";
import clsx from "clsx";
import Img from "gatsby-image";
import { useStaticQuery, graphql } from "gatsby";
import { useIntl } from "gatsby-plugin-intl";
import { makeStyles, createStyles } from "@material-ui/core/styles";

import Layout from "../../components/layout";
import SEO from "../../components/seo";
import { CommonJumbotron } from "../../components/jumbotron";
import { SlidingTypography } from "../../components/sliding";
import ContainerBox from "../../components/containerBox";
import { pageContextType } from "../../pageContextType";

const useStyles = makeStyles(theme => createStyles({
  content: {
    paddingTop: "80px",
    paddingBottom: "80px",
  },
  section: {
    paddingTop: "80px",
    paddingBottom: "80px",
  },
  flexLeft: {
    display: "flex",
    flexDirection: "row",
  },

  flexRight: {
    display: "flex",
    flexDirection: "row-reverse",
  },
  contentLead: {
    color: "#858585",
    fontSize: "24px",
    marginBottom: "160px",
  },
  title: {
    color: "#222222",
    fontSize: "40px",
    fontWeight: 700,
    marginBottom: "20px",
    lineHeight: "1.3",
    maxWidth: "670px",
    wordBreak: "keep-all",
  },
  description: {
    color: "#858585",
    fontSize: "16px",
    marginBottom: "20px",
    maxWidth: "670px",

    "&:last-child": {
      marginBottom: 0,
    },
  },
  sectionImage: {
    width: "100%",
    marginBottom: "80px",
  },
}));

const WebRTCPage = ({ pageContext }: pageContextType) => {
  const classes = useStyles();
  const intl = useIntl();
  const { language } = pageContext;
  const data = useStaticQuery(graphql`
    query {
      backgroundPlaceholder: file(
        relativePath: { eq: "tech/img-title-photo-tech-01.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      webRTCPlaceholder1: file(
        relativePath: { eq: "tech/img-tech-web-rtc@3x.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      webRTCPlaceholder2: file(
        relativePath: { eq: "tech/img-tech-web-rtc-03@3x.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <Layout language={language}>
      <SEO
        title="WebRTC"
        description={intl.formatMessage({ id: "webrtc_lead" })}
      />
      <CommonJumbotron
        backgroundImageFluid={data.backgroundPlaceholder.childImageSharp.fluid}
        title={"Web Real-Time Communication"}
      />
      <main className={classes.content}>
        <ContainerBox className={clsx(classes.section, classes.flexLeft)}>
          <div>
            <Img
              fluid={data.webRTCPlaceholder1.childImageSharp.fluid}
              className={classes.sectionImage}
              alt={intl.formatMessage({ id: "webrtc_title_1" })}
            />
            <SlidingTypography variant="h2" className={classes.title}>
              {intl.formatMessage({ id: "webrtc_title_1" })}
            </SlidingTypography>
            <SlidingTypography component="p" className={classes.description}>
              {intl.formatMessage({ id: "webrtc_description_1" })}
            </SlidingTypography>
          </div>
        </ContainerBox>

        <ContainerBox className={clsx(classes.section, classes.flexRight)}>
          <div>
            <Img
              fluid={data.webRTCPlaceholder2.childImageSharp.fluid}
              className={classes.sectionImage}
              alt={intl.formatMessage({ id: "webrtc_title_1" })}
            />
            <SlidingTypography variant="h2" className={classes.title}>
              {intl.formatMessage({ id: "webrtc_title_2" })}
            </SlidingTypography>
            <SlidingTypography component="p" className={classes.description}>
              {intl.formatMessage({ id: "webrtc_description_2" })}
            </SlidingTypography>
          </div>
        </ContainerBox>
      </main>
    </Layout>
  );
};

export default WebRTCPage;
